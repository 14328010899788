<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Pemeriksaan Tenaga Medis
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="jenis_pelayanan">Pelayanan</label>
          <Multiselect
            id="jenis_pelayanan"
            v-model="dataFilter.jenis_pelayanan"
            :options="$store.state.data_static.tkp.slice(0, 2)"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="false"
            label="text"
            track-by="tipe_rawat"
            placeholder="-- Pilih Pelayanan --"
            size="sm"
            @select="dataFilter.poli = [], dataFilter.ruang = []"
            @remove="dataFilter.poli = [], dataFilter.ruang = []"
          ></Multiselect>
        </b-col>
        <b-col cols="6">
          <label for="ms_jenis_tenaga_medis_id">Jenis Tenaga Medis</label>
          <Multiselect
            id="ms_jenis_tenaga_medis_id"
            v-model="dataFilter.ms_jenis_tenaga_medis_id"
            :options="listTenagaMedis"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama_jenis_tenaga_medis"
            track-by="ms_jenis_tenaga_medis_id"
            placeholder="-- Pilih Tenaga Medis --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="6">
          <label for="tanggal_awal">Tanggal Awal</label>
          <date-picker
            style="width: 100%"
            id="tanggal_awal"
            format="DD-MM-YYYY"
            v-model="dataFilter.tanggal_awal"
          ></date-picker>
        </b-col>
        <b-col cols="6">
          <label for="tanggal_akhir">Tanggal Akhir</label>
          <date-picker
            style="width: 100%"
            id="tanggal_akhir"
            format="DD-MM-YYYY"
            v-model="dataFilter.tanggal_akhir"
          ></date-picker>
        </b-col>
        <b-col cols="12">
          <div class="" v-if="dataFilter.jenis_pelayanan && dataFilter.jenis_pelayanan.tipe_rawat == 'RJ'">
            <div class="d-flex justify-content-between align-items-end">
              <h5 class="custom-title-card mt-2"><strong>Poliklinik</strong></h5>
              <b-form-checkbox
                id="select-all-1"
                name="select-all-1"
                v-model="dataFilter.allPoli"
                :value="true"
                :unchecked-value="false"
                @input="pilihSemuaPoli()"
              >
                <div style="margin-top: 2px;">Pilih Semua</div>
              </b-form-checkbox>
            </div>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-poli"
                v-model="dataFilter.poli"
                :aria-describedby="ariaDescribedby"
                name="checkbox-poli"
                @change="dataFilter.allPoli = false"
              >
                <b-form-checkbox :value="item.ms_poli_id" v-for="(item, key) of listPoli" :key="key">
                  <div id="poli" class="" style="margin-top: 2px;">{{item.nama_poli}}</div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="" v-if="dataFilter.jenis_pelayanan && dataFilter.jenis_pelayanan.tipe_rawat == 'RI'">
            <div class="d-flex justify-content-between align-items-end">
              <h5 class="custom-title-card mt-2"><strong>Ruangan</strong></h5>
              <b-form-checkbox
                id="select-all-1"
                name="select-all-1"
                v-model="dataFilter.allRuang"
                :value="true"
                :unchecked-value="false"
                @change="pilihSemuaRuang()"
              >
                <div style="margin-top: 2px;">Pilih Semua</div>
              </b-form-checkbox>
            </div>
            <b-form-group label="" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-ruang"
                v-model="dataFilter.ruang"
                :aria-describedby="ariaDescribedby"
                name="checkbox-ruang"
                @change="dataFilter.allRuang = false"
              >
                <b-form-checkbox :value="item.ms_ruang_id" v-for="(item, key) of listRuang" :key="key">
                  <div id="ruang" class="" style="margin-top: 2px;">{{item.nama_ruang}}</div>
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="card p-3">
            <h5 class="custom-title-card"><strong>Grafik</strong></h5>
            <canvas id="myChart" ref="serviks" style="height: 1000px; width: 100%"></canvas>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="card p-3">
            <h5 class="custom-title-card"><strong>Keterangan</strong></h5>
            <div class="" v-for="(item, key) of listPemeriksaan" :key="key">
              <div class="mt-1 d-flex justify-content-between">
                <div class="d-flex align-middle">
                  <svg :style="`color: ${backgroundColor[key % backgroundColor.length]};`" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-1-fill" viewBox="0 0 16 16">
                    <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
                  </svg>
                  <b class="ml-1">{{item.nama_tenaga_medis}}</b>
                </div>
                <div>{{item.jumlah}} ({{item.jumlah_persen}}%)</div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  data() {
    return {
      busy: false,
      myChart: null,
      dataFilter: {
        jenis_pelayanan: null,
        ms_jenis_tenaga_medis_id: null,
        interval: null,
        tanggal_awal: null,
        tanggal_akhir: null,
        poli: [],
        ruang: [],
        allPoli: false,
        allRuang: false,
      },
      listPemeriksaan: [],
      listTenagaMedis: [],
      listPoli: [],
      listRuang: [],
      listInterval:[
        'Tahun',
        'Bulan',
        'Hari',
      ],
      backgroundColor: [
        '#e3342f',
        '#f6993f',
        '#ffed4a',
        '#38c172',
        '#4dc0b5',
        '#3490dc',
        '#6574cd',
        '#9561e2',
        '#f66d9b',
      ],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.getData()
    vm.reset()
    vm.myChart = new Chart(this.$refs.serviks, {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [{
          label: 'My First Dataset',
          data: [],
          backgroundColor: vm.backgroundColor,
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
        }
      }
    })
    vm.myChart.update()
  },
  methods: {
    pilihSemuaPoli(){
      const vm = this
      vm.dataFilter.poli = []
      if(vm.dataFilter.allPoli){
        for (let i = 0; i < vm.listPoli.length; i++) {
          vm.dataFilter.poli.push(vm.listPoli[i].ms_poli_id)
        }
      }
    },
    pilihSemuaRuang(){
      const vm = this
      vm.dataFilter.ruang = []
      if(vm.dataFilter.allRuang){
        for (let i = 0; i < vm.listRuang.length; i++) {
          vm.dataFilter.ruang.push(vm.listRuang[i].ms_ruang_id)
        }
      }
    },
    async cari(){
      const vm = this
      vm.busy = false
      try {
        const res = await vm.$axios.post('/tenaga_medis/laporan_pemeriksaan_tenaga_medis', {
          tanggal_awal: vm.$moment(vm.dataFilter.tanggal_awal),
          tanggal_akhir: vm.$moment(vm.dataFilter.tanggal_akhir),
          ms_jenis_tenaga_medis_id: vm.dataFilter.ms_jenis_tenaga_medis_id ? vm.dataFilter.ms_jenis_tenaga_medis_id.ms_jenis_tenaga_medis_id : undefined,
          bulk_ms_poli_id: vm.dataFilter.poli ? vm.dataFilter.poli : undefined,
        })
        console.log('tenaga_medis/laporan_pemeriksaan_tenaga_medis', res)
        if(res.data.status == 200){
          vm.listPemeriksaan = res.data.data
          const label = []
          const data = []
          for (let i = 0; i < vm.listPemeriksaan.length; i++) {
            const x = vm.listPemeriksaan[i];
            label.push(x.nama_tenaga_medis)
            data.push(x.jumlah)
          }
          vm.myChart.data.labels = label;
          vm.myChart.data.datasets[0].data = data;
          vm.myChart.update()
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listTenagaMedis = await vm.$axios.post('/ms_jenis_tenaga_medis/list')
        vm.listTenagaMedis = listTenagaMedis.data.status == 200 ? listTenagaMedis.data.data : []
        const listPoli = await vm.$axios.post('/ms_poli/list')
        vm.listPoli = listPoli.data.status == 200 ? listPoli.data.data : []
        const listRuang = await vm.$axios.post('/ms_ruang/list')
        vm.listRuang = listRuang.data.status == 200 ? listRuang.data.data : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataFilter = {
        jenis_pelayanan: vm.$store.state.data_static.tkp.slice(0, 2)[0],
        tenaga_medis: null,
        interval: vm.listInterval[1],
        tanggal_awal: new Date(vm.$moment(vm.$store.state.tanggal_sesi).subtract(1, 'months')),
        tanggal_akhir: new Date(vm.$store.state.tanggal_sesi),
        poli: [],
        ruang: [],
        allPoli: false,
        allRuang: false,
      }
      vm.cari()
    },
    async clickSelectorAll(selector){
      let domAll = document.querySelectorAll(selector)
      console.log(selector, 'domAll', domAll)
      for (let i = 0; i < domAll.length; i++) {
        const x = domAll[i];
        x.click()
      }
    },
  },
}
</script>